jQuery(function($) {
  var form = $('form.file-upload-multipart')
  form.dropzone({
    url: form.attr('action'),
    paramName: 'dispute_file[file]',
    acceptedFiles: form.attr('data-mimes'),
    maxFilesize: 5000, // 5gb
    chunkSize: 8000000, // 8mb must be over >= 6000000 (6mb), otherwise s3 will hard fail when combining chunks
    chunking: true,
    parallelChunkUploads: false, // likely will take a bit of effort/thinking to make parallel uploads robust
    retryChunks: true,
    timeout: 35000, //in ms
    error: function(file, resp) {
      if (resp.hasOwnProperty('errors')) {
        var msg = resp.errors.join('\n')
      } else {
        var msg = resp || 'Sorry, your upload encountered an error. Please try your upload again. If the issue persists, please contact support: Error: 441';
      }
      alert(msg);
    },
    queuecomplete: function() {
      location.reload();
    }
  }).addClass('dropzone')
})

jQuery(function($) {
  var form = $('form.file-upload')
  form.dropzone({
    url: form.attr('action'),
    paramName: 'dispute_file[file]',
    acceptedFiles: form.attr('data-mimes'),
    maxFilesize: 5000, // 5gb
    timeout: 30000,
    error: function(file, resp) {
      if (resp.hasOwnProperty('errors')) {
        var msg = resp.errors.join('\n')
      } else {
        var msg = resp || 'Sorry, your upload encountered an error. Please try your upload again. If the issue persists, please contact support: Error: 203';
      }
      alert(msg);
    },
    queuecomplete: function() {
      location.reload();
    }
  }).addClass('dropzone')
})


jQuery(function($) {
  if ($('#result-form').length) {
    function setForm(form) {
      $('.result-selector-item').removeClass('active');
      $('.result-selector-item[data-form="' + form + '"]').addClass('active');
      $('.result-form').hide();
      $('.result-form[data-form="' + form + '"]').show();
      $('.calendar-date').calendar({type: 'date'})
    }
    $('.result-form').hide();
    $('.result-selector-item').click(function() {
      var form = $(this).data('form')
      setForm(form)
    })
    setForm($('#current_result_type').data('result-type'))
  }
})

jQuery(function($) {
  var form = $('#mediation_statements-upload-form')
  form.dropzone({
    url: form.attr('action'),
    paramName: 'mediation_statement[statement_file]',
    maxFilesize: 1000,
    timeout: 30000,
    acceptedFiles: 'application/pdf,audio/*,image/*,video/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    maxFiles: 1,
    error: function(file, resp) {
      if (resp.hasOwnProperty('errors')) {
        var msg = resp.errors.join('\n')
      } else {
        var msg = resp || 'Sorry, your upload encountered an error. Please try your upload again. If the issue persists, please contact support: Error: 108';
      }
      alert(msg);
    },
    queuecomplete: function() {
      location.reload();
    }
  }).addClass('dropzone')
})

jQuery(function($) {
  var form = $('#arbitration-statement-upload-form')
  form.dropzone({
    url: form.attr('action'),
    paramName: 'arbitration_statement[statement_file]',
    acceptedFiles: 'application/pdf,audio/*,image/*,video/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    maxFiles: 1,
    maxFilesize: 1000,
    timeout: 30000, //in ms
    error: function(file, resp) {
      if (resp.hasOwnProperty('errors')) {
        var msg = resp.errors.join('\n')
      } else {
        var msg = resp || 'Sorry, your upload encountered an error. Please try your upload again. If the issue persists, please contact support: Error: 224';
      }
      alert(msg);
    },
    queuecomplete: function() {
      location.reload();
    }
  }).addClass('dropzone')
})

jQuery(function($) {
  var form = $('#final_arguments-upload-form')
  form.dropzone({
    url: form.attr('action'),
    paramName: 'final_argument[statement_file]',
    acceptedFiles: 'application/pdf,audio/*,image/*,video/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    maxFiles: 1,
    maxFilesize: 1000,
    timeout: 30000, //in ms
    error: function(file, resp) {
      if (resp.hasOwnProperty('errors')) {
        var msg = resp.errors.join('\n')
      } else {
        var msg = resp || 'Sorry, your upload encountered an error. Please try your upload again. If the issue persists, please contact support: Error: 301';
      }
      alert(msg);
    },
    queuecomplete: function() {
      location.reload();
    }
  }).addClass('dropzone')
})

jQuery(function($) {
  if ($('#schedule-details').length) {
    var pollSchedule = function () {
      $.get('', {}, function(result) {
        $('#schedule-details').html(result);
      })
    }
    setInterval(function () {
      pollSchedule();
    }, 20 * 1000) // 20s
  }
  if ($('#neutral-scheduling').length) {
    var pollSchedule = function () {
      $.ajax({url: '', dataType: 'json', success: function (json) {
        if (json.vyte_event_id) {
          window.location.reload();
        }
      }})
    }
    setInterval(function () {
      pollSchedule();
    }, 20 * 1000)
  }
})
