var jstz = require('jstimezonedetect');

jQuery(function($) {
  if (window.currentTimeZone) {
    // try updating the user's timezone based on detection
    var tz = jstz.determine().name();
    if (tz && tz != window.currentTimeZone) {
      $.ajax('/account',
        {
          method: 'PUT',
          data: {
            'user[time_zone]': tz
          },
          dataType: 'json'
        }
      )
    }
  }
})