jQuery(function($) {
  $('.ui.right.sidebar').sidebar({
    transition: 'overlay',
    onVisible: function() {
      url = $('#activity').data('refresh-path');
      $.ajax({url: url, type: 'GET', success: function(result){
        $("#activity-sidebar").html(result);
      }});
    }
  })
  .sidebar('attach events', '#activity');
})
