jQuery(function($) {
  $('.result_select').click(function() {
    $(".result_select").each(function() {
      $(this).removeClass('active');
    });
    $('#neutral-decision-text-form').removeClass('hidden');
    $(this).addClass('active');
    var selection = $(this).data('selection');
    $('#result_result_type').val(selection);
  });
})
