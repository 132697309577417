jQuery(function($) {
  $('.read-notification:checkbox').click(function() {
    var state = ($('.read-notification:checkbox:checked').length === 0)
    $('#notifications-mark-as-read').prop('disabled', state);
  })

  $('#notifications-select-all').click(function() {
    var checked = this.checked;
    $('#notifications-mark-as-read').prop('disabled', !checked); 
    $('.read-notification:checkbox').each(function() {
      this.checked = checked;
    });
  })
  $('#notifications-mark-as-read').click(function () {
    var checked = $('.read-notification:checkbox:checked').map(function() {
      return this.name;
    }).get();
    var state = $(this).data('current-state');

    if (checked.length === 0) { return }

    $.ajax({
      url: '/notifications/toggle_read',
      type: 'PUT',
      data: {notifications: checked, state: state},
      success: function(data) {
        location.reload();
      }
    });
  })
})
