import html2canvas from 'html2canvas';

jQuery(function($) {
  $( document ).ready(function() {
    $("#snap-screenshot").click(function () {
      console.log("starting screenshot");
      const screenshotTarget = document.body;

      html2canvas(document.body).then(function(canvas) {
        downloadImage(canvas.toDataURL(), 'NewEraScreenshot.png');
      });
    })
  });

  function downloadImage(uri, filename) {
    var link = document.createElement('a');
    if (typeof link.download !== 'string') {
      window.open(uri);
    } else {
      link.href = uri;
      link.download = filename;
      link.click();
    }
  }
})
