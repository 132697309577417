jQuery(function ($) {
  $('.password-toggler, .password-toggler-input > .icon').on('click', function() {
    $('.toggleable-password').each(function () {
      var password = this;
      if (password.type === "password") {
        password.type = "text";
        $(this).parents().find('.icon').addClass('slash')
      } else {
        password.type = "password";
        $(this).parents().find('.icon').removeClass('slash')
      }
    })
  })
})
