/* eslint-env jquery */
// Show the customer the error from Stripe if their card fails to charge
var showError = function(errorMsgText) {
  loading(false)
  var errorMsg = $("#card-error")
  errorMsg.show()
  errorMsg.text(errorMsgText)
}

var hideError = function() {
  var errorMsg = $("#card-error")
  errorMsg.text('')
  errorMsg.hide()
}

var enableSubmit = function (enable) {
  var submitButton = $('#submit-payment-button')
  var openSubmitModalButton = $('#open-submission-modal')
  if (enable) {
    submitButton.removeAttr('disabled')
    openSubmitModalButton.removeAttr('disabled')
  } else {
    submitButton.attr('disabled', 'disabled')
    openSubmitModalButton.attr('disabled', 'disabled')
  }
}
// Show a spinner on payment submission
var loading = function (isLoading) {
  var loader = $('.loader')
  if (isLoading) {
    // Disable the button and show a spinner
    loader.addClass('active')
    enableSubmit(false)
  } else {
    loader.removeClass('active')
    enableSubmit(true)
  }
}

var initializePayments = function (stripe, clientSecret) {
  var paymentComplete = false
  enableSubmit(false)
  var elements = stripe.elements()
  var style = {
    base: {
      color: "#335577",
      backgroundColor: "#f7f9fc",
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#335577"
      }
    },
    invalid: {
      fontFamily: 'Roboto, sans-serif',
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
  var card = elements.create("card", { style: style })
  // Stripe injects an iframe into the DOM
  card.mount("#card-element")
  card.on("change", function (event) {
    // Disable the Pay button if there are no card details in the Element
    hideError()
    enableSubmit(!event.empty)
  })

  // for confirmation modal-having forms:
  var modalPayButton = $('.modal-payment-button') // if using the modal, this button is also the submit-payment-button
  modalPayButton.on("click", function(event) {
    if (!paymentComplete) {
      event.preventDefault()
      // Complete payment when the submit button is clicked
      payWithCard(stripe, card, clientSecret, true)
    }
  })

  // payment submitting form:
  var form = $('#payment-form')
  form.on("submit", function(event) {
    if (!paymentComplete) {
      event.preventDefault()
      // Complete payment when the submit button is clicked
      payWithCard(stripe, card, clientSecret)
    }
  })
  var payWithCard = function(stripe, card, clientSecret) {
    loading(true)
    stripe
      .confirmCardPayment(clientSecret, {payment_method: {card: card}})
      .then(function(result) {
        $("#submission-modal").modal('hide');
        if (result.error) {
          // Show error to your customer
          showError(result.error.message)
        } else {
          // The payment succeeded!
          paymentComplete = true
          orderComplete(result.paymentIntent.id)
        }
      })
  }
  // Shows a success message when the payment is complete
  var orderComplete = function(paymentIntentId) {
    loading(false)
    $('#payment_id_input').val(paymentIntentId)
    form.submit()
  }
}

export { initializePayments }
