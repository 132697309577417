jQuery(function($) {
  // simple show/hide on radio selection
  $('.radio_reveal').change(function () {
    var radio = $(this)
    var revealable = radio.data("form-to-show");
    var hideable = radio.data("form-to-hide");
    var fieldToRequire = radio.data("field-to-require");

    if (event.target.checked && revealable) {
      $(revealable).show();
    }
    if (event.target.checked && hideable) {
      $(hideable).hide();
    }

    if (event.target.checked && fieldToRequire) {
      $(fieldToRequire).prop('required', true)
    }
  })

  $('.click-to-hide').click(function () {
    var toHide = $(this).data("form-to-hide");
    $(toHide).hide();

    var toClear = $(this).data("field-to-clear");
    if (toClear) {
      $(toClear).val('');
    }

    var toMakeOptional = $(this).data("field-to-make-optional");
    if (toMakeOptional) {
      $(toMakeOptional).prop('required', false)
    }
  })

  $('.click-reveal').click(function () {
    var unhide = $(this).data("form-to-reveal");
    $(unhide).slideDown();
  })

  // simple toggle form/div
  $('.toggleable').click(function () {
    var toggleable = $(this).data("form-to-toggle");
    $(toggleable).slideToggle();
  })

  function asyncDelay(delayMS=1000) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, delayMS);
    });
  }

  // swap multiple forms
  $('.swappable').click(function () {
    var forms = $(this).data("forms-to-toggle").split(",");
    forms.forEach(function(form) {
      $(form).toggle();
    });
  })

  // show form, hide clicked button
  $('.continuable').click(function () {
    var continuable = $(this).data("form-to-show");
    $(continuable).toggle();
    $(this).hide();
  })

  $('.backable').click(function () {
    var backToId = $(this).data("step-back-to");
    $('.step-container').hide();
    $(`#${backToId}`).show();
  })
})

var initializeCalendars = function() {
  $('.ui.calendar').calendar({
    type: 'datetime',
    disableMinute: true
  })
  $('.ui.calendar.datetime-and-minute').calendar({
    type: 'datetime',
    minTimeGap: 15,
  })
  $('.ui.calendar.date').calendar({
    type: 'date',
  })
}

jQuery(function($) {
  $('.select-dropdown').click(function () {
    $(this).dropdown({ action: 'select' });
  })
})


jQuery(function($) {
  $( document ).ready(function() {
    // expects a div#ajax-result as the target for result html
    // ignores the response if it is not html (like when we want to execute JS
    // to transition the page to the next wizard step via next_action.js)
    document.querySelectorAll(".ajax-result-display").forEach(form => {
      let resultId = $(form).data("ajax-result-id") || "#ajax-result";

      form.addEventListener("ajax:error", (event) => {
        const [_data, _status, xhr] = event.detail;
        Newera.displayAjaxResult(xhr, resultId);
      });
      form.addEventListener("ajax:success", (event) => {
        const [_data, _status, xhr] = event.detail;
        Newera.displayAjaxResult(xhr, resultId);
      });
      form.addEventListener("submit", (event) => {
        Newera.clearAjaxResult(resultId);
      });
    });
  });
})

// when the checkbox is checked, the given button is enabled;
// when unchecked, the given button is disabled.
jQuery(function($) {
  $(document).ready(function(){
    $('.checkbox-check-to-enable').change(function(){
      var buttonToEnableId = $(this).data('button-to-enable-id');

      if (this.checked) {
        $(buttonToEnableId).removeAttr('disabled')
      } else {
        $(buttonToEnableId).attr('disabled', 'disabled')
      }
    })
  })
});
var clearAjaxResult = function(resultId) {
  var responseDiv = document.querySelector(resultId);
  responseDiv.innerHTML = '';
}

var displayAjaxResult = function(xhrResponse, resultId) {
  if (xhrResponse.getResponseHeader('Content-Type').indexOf('text/html') !== 0) { return; }
  var html = xhrResponse.responseText
  var responseDiv = document.querySelector(resultId);
  responseDiv.innerHTML = html;
  if (html.length > 0) {
    responseDiv.scrollIntoView();
  }
}

export {
  displayAjaxResult,
  clearAjaxResult,
  initializeCalendars
}
