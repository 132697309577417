jQuery(function($) {
  var inProgress = {};
  if ($('#messages').length) {
    var defaultParams = $('#messages').data();
    var form = $('#new-message-form')
    var lsKey = 'messageForm';

    function fillFormFromLocalStorage() {
      var formData = localStorage.getItem(lsKey)
      if (formData) {
        form.find('textarea').val(formData)
        localStorage.removeItem(lsKey)
      }
    }
    function setLocalStorageFromForm() {
      localStorage.setItem(lsKey, form.find('textarea').val())
    }

    function formReset() {
      form.find('textarea').val('').focus();
    }
    function showLoading() {
      $('#messages .loader').addClass('active')
    }
    function hideLoading() {
      $('#messages .loader').removeClass('active')
    }
    function showingFirstMessage() {
      return $('#messages .comment:first').data('first-message');
    }
    function fetchMessages(params, callback) {
      params = $.extend({}, defaultParams, params)
      url = $('#messages').data('refresh-path');
      $.ajax({url: url, data: params, type: 'GET', success: callback});
    }
    function loadMessages(beforeId, afterId, callback) {
      if (beforeId && !inProgress[beforeId]) {
        inProgress[beforeId] = true;
        fetchMessages({before_id: beforeId}, function(response) {
          delete inProgress[beforeId];
          if (response.length) {
            $('#messages').prepend(response);
          }
          if (callback) { callback(); }
        })
      } else if (afterId && !inProgress[afterId]) {
        inProgress[afterId] = true;
        fetchMessages({after_id: afterId}, function(response) {
          delete inProgress[afterId];
          if (response.length) {
            $('#messages').append(response);
            scrollToBottom();
          }
          if (callback) { callback(); }
        })
      } else {
        fetchMessages({}, function(response) {
          if (response.length) {
            $('#messages').html(response);
            scrollToBottom();
          }
          if (callback) { callback(); }
        })
      }
    }
    function firstMessageId() {
      return $('#messages .comment:first').data('id');
    }
    function lastMessageId() {
      return $('#messages .comment:last').data('id');
    }
    // poll for new messages periodicaly
    function pollForMessages() {
      setTimeout(function() {
        loadMessages(false, lastMessageId(), pollForMessages)
      }, 10000) // poll every 10s
    }
    function scrollToBottom() {
      const messagesContainer = $('#messages');
      messagesContainer.animate(
        { scrollTop: messagesContainer[0].scrollHeight },
        400 // anim smoothing in ms
      );
    }
    form
    .keydown(function(event) { // submit the form on ctl+enter
      if (event.ctrlKey && event.keyCode === 13) {
        $(this).trigger('submit.rails')
      }
    })
    .on("ajax:success", function(event) {
      loadMessages(false, lastMessageId())
      formReset();
    })

    $('#messages').scroll(function() {
      var scrolltop = $(this).scrollTop()
      if (scrolltop == 0) {
        if (!showingFirstMessage()) {
          showLoading();
          loadMessages(firstMessageId(), false, hideLoading);
        }
      }
    })

    fillFormFromLocalStorage();
    pollForMessages()
    scrollToBottom()
    $(window).bind('beforeunload', setLocalStorageFromForm)
    // reload the whole window to refresh the CSRF token
    setTimeout(function () {
      window.location.reload()
    }, 60 * 60 * 1000); // 1 hour
    var scrollTimeout;
    $(window).scroll(function() {
      if (!scrollTimeout) {
        scrollTimeout = setTimeout(function () {
          scrollTimeout = false;
        }, 1000);
      }
    })
  }



  const newMessageForm = document.querySelector("#new-message-form");
  if (newMessageForm) {
    newMessageForm.addEventListener("ajax:error", (event) => {
      const [_data, _status, xhr] = event.detail;
      var res = JSON.parse(xhr.responseText);
      alert(res["error"]);
    });
  }
})
